import React from "react";

import { Col, Row } from "@nowadays/UI/Grid";

import Media from "./components/Media";

const MediaModule = props => {
  const { module: { media }, project } = props;

  const col = 12 / media.length;

  return (
    <Row>
      {
        media.map((mediaItem, i) => {
          return (
            <Col
              key={`project-${ project.slug }__project-module__media-k-${ i }`}
              col={col}
            >
              <Media
                allMediaCount={media.length}
                media={mediaItem}
              />
            </Col>
          );
        })
      }
    </Row>
  );
};

export default MediaModule;
