import React from "react";
import Img from "gatsby-image";

const Image = props => {
  const { altText, image } = props;

  const hotspot = image.hotspot || {};

  const {
    x=0.5,
    y=0.5
  } = hotspot;

  const fluid = image.asset.fluid;

  return (
    <Img
      alt={altText}
      fadeIn={false}
      fluid={fluid}
      loading="lazy"
      objectFit="cover"
      objectPosition={`${ x * 100 }% ${ y * 100 }%`}
      style={
        {
          height: "100%",
          position: "relative",
          width: "100%",
          willChange: "transform"
        }
      }
      placeholderStyle={
        {
          filter: "blur(20px)"
        }
      }
    />
  );
};

export default Image;
