import classNames from "classnames";
import { media } from "styled-bootstrap-grid";
import styled from "styled-components";

const NavButtons = styled.div.attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(className, "project__nav-buttons")
  };
})`
  ${ ({ theme: { baseUnit, borders, colors, space } }) => `
    align-items: center;
    // Combination gradient background-image and box-shadow to support
    // cross-browser subpixel borders. Be careful reconfiguring these values.
    background-image:
      linear-gradient(
        to top, ${ colors.white } 0%, ${ colors.white } 51%, transparent 51%
      );
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: 100% 1px;
    box-shadow: ${ borders.bottom } ${ colors.white };
    display: flex;
    justify-content: space-between;
    margin-bottom: ${ space.xxSmall};

    &:last-child {
      background-image: none;
      box-shadow: none;
      margin-bottom: 0;
    }
  `}

  ${media.sm`
    ${ () => `
      margin-bottom: 0;
    `}
  `}
`;

NavButtons.propTypes = {};

NavButtons.defaultProps = {};

export default NavButtons;
