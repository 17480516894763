import classNames from "classnames";
import styled from "styled-components";

const Cell = styled.div.attrs(props => {
  const {
    className,
  } = props;

  return {
    className: classNames(className, "project__cell")
  };
})`
  ${({ theme: { space } }) => `
    transform: translateZ(0);

    & + & {
      margin-top: ${ space.xxSmall };
    }
  `}
`;

Cell.propTypes = {};

Cell.defaultProps = {};

export default Cell;
