import React from "react";
import BlockContent from "@sanity/block-content-to-react";

import { Anchor } from "@nowadays/UI/Button";
import { Col, Row } from "@nowadays/UI/Grid";
import { ResponsiveParagraph } from "@nowadays/UI/Paragraph";
import { ResponsiveText } from "@nowadays/UI/Typography";

import Client from "./views/Client";
import StyledHeader from "./views/Header";
import TextsWrapper from "./views/TextsWrapper";

const serializers = {
  types: {
    block: ({ children }) => (
      <ResponsiveParagraph sm="large" xs="xSmall" withMeasure>
        { children }
      </ResponsiveParagraph>
    )
  },
  marks: {
    link: ({ children, mark }) => (
      mark.newTab ? (
        <Anchor href={mark.href} target="_blank" rel="noopener noreferrer">
          { children }
        </Anchor>
      ) : (
        <Anchor href={mark.href}>
          { children }
        </Anchor>
      )
    )
  }
};

const Header = props => {
  const { project: { client, title, _rawDescription } } = props;

  return (
    <StyledHeader>
      <Row>
        <Col xl="5" lg="3" md="12">
          <Client>
            { client }
          </Client>
          <TextsWrapper>
            <ResponsiveText expanded sm="xxLarge" xs="xLarge">
              { title }
            </ResponsiveText>
          </TextsWrapper>
        </Col>
        <Col xl="6" xlOffset="1" lg="8" lgOffset="1" md="12">
          <BlockContent blocks={_rawDescription} serializers={serializers}/>
        </Col>
      </Row>
    </StyledHeader>
  );
};

export default Header;
