import classNames from "classnames";
import styled from "styled-components";

const PlayerWrapper = styled.div.attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(
      className,
      "project__media-module__video__player-wrapper"
    )
  };
})`

  ${({ height, width, thumbnailSrc }) => `
    overflow: hidden;
    // Padding trick for height, keeping element at the correct aspect ratio.
    padding-bottom: ${ (height / width) * 100 }%;
    position: relative;
    transform: translateZ(0);
    width: 100%;

    &:before {
      background-image: url(${ thumbnailSrc });
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      bottom: 0;
      content: "";
      //filter: blur(10px);
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }

    > div {
      height: 100%;
      position: absolute;
      width: 100%;

      > iframe {
        height: 100%;
        width: 100%;
      }
    }
  `}
`;

PlayerWrapper.defaultProps = {};

export default PlayerWrapper;
