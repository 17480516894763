import React from "react";

import ImageTextModule from "./components/ImageTextModule";
import MediaModule from "./components/MediaModule";

const ProjectModule = props => {
  const { module, project } = props;

  let ModuleComponent;

  switch (module._type) {
    case "projectImageTextModule":
       ModuleComponent = ImageTextModule;
      break;
    case "projectMediaModule":
      ModuleComponent = MediaModule;
      break;
    default:
      console.error(`ProjectModule value for 'type', '${ module._type }', not supported.`);
      return null;
  }

  return <ModuleComponent module={module} project={project}/>;
};

export default ProjectModule;
