import React from "react";

import Image from "./components/Image";
import Video from "./components/Video";

const Media = props => {
  const { allMediaCount, media } = props;

  let MediaComponent;

  switch (media._type) {
    case "detailedImage":
      MediaComponent = Image;
      break;
    case "detailedVideo":
      MediaComponent = Video;
      break;
    default:
      console.error(
        `Media value for 'type', '${ module._type }', not supported.`
      );
      return null;
  }

  return (
    <MediaComponent
      allMediaCount={allMediaCount}
      media={media}
    />
  );
};

export default Media;
