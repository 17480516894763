import classNames from "classnames";
import { media } from "styled-bootstrap-grid";
import styled from "styled-components";

import { XXSmallText } from "@nowadays/UI/Typography";

const Client = styled(XXSmallText).attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(className, "project__header__client")
  };
})`
  ${() => `
    margin-bottom: 3px;
  `}

  ${media.sm`
    ${({ theme: { space } }) => `
      margin-bottom: ${ space.xxxSmall };
    `}
  `}
`;

Client.propTypes = {};

Client.defaultProps = {
  expanded: true,
  spacing: "small",
  uppercase: true
};

export default Client;
