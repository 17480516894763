import React, { useEffect, useState } from "react";

import PlayerWrapper from "./views/PlayerWrapper";

const Video = props => {
  const {
    media: {
      vimeoVideo
    }
  } = props;

  const {
    oEmbedData: {
      html,
      width,
      height,
      thumbnail_url
    }
  } = vimeoVideo;

  const [didMount, setDidMount] = useState(false);

  // Add lazy loading to iframe. This will help images load in a more pleasant
  // way while scrolling, as they also lazy load. Without this, they tend to get
  // backed up due to requests to vimeo being in-flight.
  const lazyIframe = html.replace(/\<iframe\s/g, '<iframe loading="lazy" ');

  useEffect(() => setDidMount(true), []);

  return (
    <PlayerWrapper height={height} width={width} thumbnailSrc={thumbnail_url}>
      {
        // We wait for mount to set the inner iframe html in order to prevent
        // Gatsby from "prefetching" vimeo embeds on page transitions, which
        // leads to the user having to wait for vimeo servers to respond with
        // data per video during any route change.
        didMount && (
          <div dangerouslySetInnerHTML={{ __html: lazyIframe }}/>
        )
      }
    </PlayerWrapper>
  );
};

export default Video;
