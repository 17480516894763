import React from "react";

import { Metadata } from "@nowadays/Core";
import { Container } from "@nowadays/UI/Grid";

import Header from "./components/Header";
import ProjectModule from "./components/ProjectModule";
import Cell from "./views/Cell";
import NavButton from "./views/NavButton";
import NavButtons from "./views/NavButtons";

const Project = props => {
  const { data: { project, allProjects: { edges: projects } } } = props;

  const thisIndex = projects.findIndex(
    ({ node: p }) => p.slug.current === project.slug.current
  );

  const prevSlug = thisIndex === 0
    ? projects[projects.length - 1].node.slug.current
    : projects[thisIndex - 1].node.slug.current;

  const nextSlug = thisIndex === (projects.length - 1)
    ? projects[0].node.slug.current
    : projects[thisIndex + 1].node.slug.current;

  return (
    <React.Fragment>
      <Metadata metadata={project.metadata}/>
      <NavButtons>
        <NavButton direction="left" to={`/projects/${ prevSlug }`}/>
        <NavButton direction="right" to={`/projects/${ nextSlug }`}/>
      </NavButtons>
      <Container>
        <Cell>
          <Header project={project}/>
        </Cell>
        {
          project.projectModules.map((module, i) => (
            <Cell key={`project-${ project.slug }__project-module-k-${ i }`}>
              <ProjectModule
                module={module}
                project={project}
              />
            </Cell>
          ))
        }
      </Container>
      <NavButtons>
        <NavButton direction="left" to={`/projects/${ prevSlug }`}/>
        <NavButton direction="right" to={`/projects/${ nextSlug }`}/>
      </NavButtons>
    </React.Fragment>
  );
};

export default Project;
