import React from "react";
import { graphql } from "gatsby";

import ProjectsShowPageComponent from "@nowadays/Pages/ProjectsShow";

const Project = props => {
  const { data } = props;

  return (
    <ProjectsShowPageComponent data={data}/>
  );
};

export const query = graphql`
  query Project($slug: String) {
    project: sanityProject(slug: { current: { eq: $slug } }) {
      ...fieldsOnProject
    }
    allProjects: allSanityProject(
      sort: {
        fields: [releaseDate]
        order: DESC
      }
    ) {
      edges {
        node {
          releaseDate
          slug {
            current
          }
        }
      }
    }
  }
`;

export default Project;
