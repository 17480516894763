import classNames from "classnames";
import { media } from "styled-bootstrap-grid";
import styled from "styled-components";

import { ResponsiveText } from "@nowadays/UI/Typography";

const Title = styled(ResponsiveText).attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(className, "project__image-text-module__text__title")
  };
})`
  ${({ theme: { space } }) => `
    margin-bottom: ${ space.xxSmall };
  `}

  ${media.sm`
    ${({ theme: { space } }) => `
      margin-bottom: ${ space.small };
    `}
  `}

  ${media.lg`
    ${({ theme: { space } }) => `
      margin-bottom: ${ space.large };
    `}
  `}
`;

Title.propTypes = {};

Title.defaultProps = {
  expanded: true,
  lg: "xxLarge",
  sm: "large",
  xs: "small"
};

export default Title;
