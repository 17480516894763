import React from "react";
import BlockContent from "@sanity/block-content-to-react";

import { Anchor } from "@nowadays/UI/Button";
import { Col, Row } from "@nowadays/UI/Grid";
import { ResponsiveParagraph } from "@nowadays/UI/Paragraph";

import Subtitle from "./views/Subtitle";
import StyledText from "./views/Text";
import Title from "./views/Title";

const serializers = {
  types: {
    block: ({ children }) => (
      <ResponsiveParagraph lg="large" sm="medium" xs="xSmall" withMeasure>
        { children }
      </ResponsiveParagraph>
    )
  },
  marks: {
    link: ({ children, mark }) => (
      mark.newTab ? (
        <Anchor href={mark.href} target="_blank" rel="noopener noreferrer">
          { children }
        </Anchor>
      ) : (
        <Anchor href={mark.href}>
          { children }
        </Anchor>
      )
    )
  }
};

const ImageTextModule = props => {
  const {
    description,
    subtitle,
    title
  } = props;

  return (
    <StyledText>
      <Row>
        <Col col="12">
          <Subtitle>
            { subtitle }
          </Subtitle>
          <Title>
            { title }
          </Title>
        </Col>
        <Col col="12">
          <BlockContent blocks={description} serializers={serializers}/>
        </Col>
      </Row>
    </StyledText>
  );
};

export default ImageTextModule;
