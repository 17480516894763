import classNames from "classnames";
import { media } from "styled-bootstrap-grid";
import styled from "styled-components";

import { XXSmallText } from "@nowadays/UI/Typography";

const Subtitle = styled(XXSmallText).attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(
      className,
      "project__image-text-module__text__subtitle"
    )
  };
})`
  ${({ theme: { space } }) => `
    display: none;
  `}

  ${media.lg`
    ${({ theme: { space } }) => `
      display: block;
    `}
  `}
`;

Subtitle.propTypes = {};

Subtitle.defaultProps = {
  expanded: true,
  uppercase: true
};

export default Subtitle;
