import classNames from "classnames";
import { media } from "styled-bootstrap-grid";
import styled from "styled-components";

const TextsWrapper = styled.div.attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(className, "project__header__texts-wrapper")
  };
})`
  ${({ theme: { lineHeights } }) => `
    margin-bottom: ${ lineHeights.xxSmall };
  `}

  ${media.sm`
    ${({ theme: { lineHeights } }) => `
      text-overflow: ellipsis;
      margin-bottom: ${ lineHeights.large };
      word-break: break-word;
      -webkit-line-clamp: 5; /* number of lines to show */
      -webkit-box-orient: vertical;
    `}
  `}

  ${media.lg`
    ${() => `
      margin-bottom: 0;
    `}
  `}
`;

TextsWrapper.propTypes = {};

TextsWrapper.defaultProps = {};

export default TextsWrapper;
