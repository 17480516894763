import classNames from "classnames";
import { media } from "styled-bootstrap-grid";
import styled from "styled-components";

const Text = styled.div.attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(
      className,
      "project__image-text-module__text"
    )
  };
})`
  ${({ theme: { space } }) => `
    padding: ${ space.small } ${ space.small } ${ space.xxSmall };
  `}


  ${media.sm`
    ${({ theme: { space } }) => `
      padding: ${ space.small } ${ space.large } ${ space.xxSmall };
    `}
  `}

  ${media.lg`
    ${({ theme: { borders, colors, space } }) => `
      // Combination gradient background-image and box-shadow to support
      // cross-browser subpixel borders. Be careful reconfiguring these values.
      background-image:
        linear-gradient(
          to top, ${ colors.white } 0%, ${ colors.white } 51%, transparent 51%
        );
      background-position: bottom;
      background-repeat: no-repeat;
      background-size: 100% 1px;
      box-shadow: ${ borders.bottom } ${ colors.white };
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: flex-end;
      padding: ${ space.large };
      text-overflow: ellipsis;
      word-break: break-word;
      -webkit-line-clamp: 5; /* number of lines to show */
      -webkit-box-orient: vertical;
    `}
  `}
`;

Text.propTypes = {};

Text.defaultProps = {};

export default Text;
