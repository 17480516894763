import classNames from "classnames";
import { media } from "styled-bootstrap-grid";
import styled from "styled-components";

const Header = styled.header.attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(className, "project__header")
  };
})`
  ${({ theme: { space } }) => `
    padding: ${ space.xxSmall } ${ space.small };
  `};

  ${media.sm`
    ${({ theme: { space } }) => `
      padding: ${ space.xLarge } ${ space.large } ${ space.large };
    `}
  `}
`;

Header.propTypes = {};

Header.defaultProps = {};

export default Header;
