import React from "react";

import { Col, Row } from "@nowadays/UI/Grid";

import Image from "./components/Image";
import Text from "./components/Text";

const ImageTextModule = props => {
  const {
    module: {
      altText,
      image,
      layout,
      reverseLayout,
      subtitle,
      title,
      _rawDescription
    }
  } = props;

  let textColLg;

  switch (layout) {
    case "oneThird":
      textColLg = 4;
      break;
    case "half":
      textColLg = 6;
      break;
    case "twoThird":
      textColLg = 8;
      break;
    default:
      console.error(
        `ImageTextModule value for 'layout', '${ layout }', not supported.`
      );
      return null;
  }

  const imageColLg = 12 - textColLg;

  return (
    <Row>
      <Col
        lg={textColLg}
        lgOrder={ reverseLayout ? "2" : "1"}
        sm="12"
        smOrder="2"
        xs="12"
        xsOrder="2"
      >
        <Text description={_rawDescription} subtitle={subtitle} title={title}/>
      </Col>
      <Col
        lg={imageColLg}
        lgOrder={ reverseLayout ? "1" : "2"}
        sm="12"
        smOrder="1"
        xs="12"
        xsOrder="1"
      >
        <Image altText={altText} image={image}/>
      </Col>
    </Row>
  );
};

export default ImageTextModule;
