import React from "react";
import classNames from "classnames";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import { media } from "styled-bootstrap-grid";
import styled, { withTheme } from "styled-components";
import { KeyboardFocusContainer } from "@zendeskgarden/container-keyboardfocus";

import { LeftArrow, RightArrow } from "@nowadays/UI/Icon";

const DIRECTIONS = {
  LEFT: "left",
  RIGHT: "right"
};

const buttonResets = `
  border: none;
  background: none;
  cursor: pointer;
  font: inherit;
  outline: none;
  padding: 0;
`;

const StyledNavButton = styled(Link).attrs(props => {
  const {
    className,
    direction,
    focused
  } = props;

  return {
    className: classNames(
      className,
      "project__nav-button", {
        "project__nav-button--left": direction === DIRECTIONS.LEFT,
        "project__nav-button--right": direction === DIRECTIONS.RIGHT,

        "project__nav-button--is-focused": focused
      })
  };
})`
  ${ buttonResets }

  ${({ theme: { borders, colors, space } }) => `
    align-items: center;
    display: flex;
    flex: 1 0 0;
    height: ${ space.large };
    padding: 0 ${ space.xxSmall };

    &.project__nav-button--left {
      justify-content: flex-start;
    }

    &.project__nav-button--right {
      justify-content: flex-end;
    }

    // :focus managed by KeyboardFocusContainer
    &.project__nav-button--is-focused
    {
      outline: auto;
    }

    svg {
      vertical-align: top;
      height: ${ space.xxSmall };
    }
  `}

  ${media.sm`
    ${ ({ theme: { space } }) => `
      && {
        height: ${ space.xxLarge };

        svg {
          height: ${ space.xSmall };
        }
      }
    `}
  `}
`;

const NavButton = React.forwardRef(({ direction, focused, theme, ...other }, ref) => (
  <KeyboardFocusContainer>
    {({ getFocusProps, keyboardFocused }) => (
      <StyledNavButton
        {...getFocusProps({
          ref,
          direction,
          ...other,
          focused: keyboardFocused ? "true" : null
        })}
      >
        {
          direction === DIRECTIONS.RIGHT ? (
            <RightArrow color={theme.colors.white}/>
          ) : (
            <LeftArrow color={theme.colors.white}/>
          )
        }
      </StyledNavButton>
    )}
  </KeyboardFocusContainer>
));

NavButton.propTypes = {
  direction: PropTypes.oneOf(
    [
      DIRECTIONS.LEFT,
      DIRECTIONS.RIGHT
    ]
  )
};

NavButton.defaultProps = {
  direction: DIRECTIONS.RIGHT
};

export default withTheme(NavButton);
